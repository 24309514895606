import React from "react";
import "./OtherFacility.css";
import { getAssetPath } from "../../services/helpers";

import { useTranslation } from "react-i18next";

const OtherFacility = () => {
  const { t } = useTranslation();

  const { banner, facility, containers } = t("otherFacility");

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="canedeve-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">
              {banner.heading1} <br />
              {banner.heading2}
            </h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="simple-idea abt-pdtop cane-deve-bg">
        <div className="row mrg-0">
          <div className="container col-md-6">
                      <h2 className="idea-title">{facility.heading}</h2>
          </div>
          <div className="container col-md-10">
            <p className="idea-para" style={{marginBottom : '100px'}}>{facility.para}</p>
          </div>
        </div>
      </section>
      <section className="candeve-content-bg">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath("assets/imgs/other-facility/vima.jpeg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {containers.container1.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                  {containers.container1.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath(
                      "assets/imgs/other-facility/suraksha.jpeg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">{containers.container2.heading}</h4>
                  <p className="facility-desc font-regular">
                  {containers.container2.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath(
                      "assets/imgs/other-facility/prashikshan.jpeg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                  {containers.container3.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                  {containers.container3.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath(
                      "assets/imgs/other-facility/sevak-mandal.jpeg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">{containers.container4.heading}</h4>
                  <p className="facility-desc font-regular">
                  {containers.container4.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath("assets/imgs/other-facility/kamgar-gratuity.png")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                  {containers.container5.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                  {containers.container5.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath(
                      "assets/imgs/other-facility/gunvant.jpeg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                  {containers.container6.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                  {containers.container6.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath("assets/imgs/other-facility/arogya.jpeg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">{containers.container7.heading}</h4>
                  <p className="facility-desc font-regular">
                  {containers.container7.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath("assets/imgs/other-facility/kamgar.jpeg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">{containers.container8.heading}</h4>
                  <p className="facility-desc font-regular">
                  {containers.container8.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath("assets/imgs/other-facility/camp.png")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                  {containers.container9.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                  {containers.container9.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath("assets/imgs/other-facility/prathamopchar.jpeg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">{containers.container10.heading}</h4>
                  <p className="facility-desc font-regular">
                  {containers.container10.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath("assets/imgs/other-facility/kamgar-surkasha.jpeg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">{containers.container11.heading}</h4>
                  <p className="facility-desc font-regular">
                  {containers.container11.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath("assets/imgs/other-facility/pagar-pf.png")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">{containers.container12.heading}</h4>
                  <p className="facility-desc font-regular">
                  {containers.container12.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="other-facility"
                    src={getAssetPath("assets/imgs/other-facility/kusti.jpeg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">{containers.container13.heading}</h4>
                  <p className="facility-desc font-regular">
                  {containers.container13.para}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtherFacility;
