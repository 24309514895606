
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Home2 from './pages/Home2/home2';
import AboutUs from "./pages/About/AboutUs";
import OurTeam from "./pages/About/OurTeam";
import CaneDevelopment from "./pages/About/CaneDevelopment";
import OtherFacility from "./pages/About/OtherFacility";
import Administration from "./pages/Departments/Administration/Administration";
import Accounts from "./pages/Departments/Accounts/Accounts";
import Agriculture from "./pages/Departments/Agriculture/Agriculture";
import Manufacturing from "./pages/Departments/Manufacturing/Manufacturing";
import Labortime from "./pages/Departments/Labor-Time/Labortime";
import DeptCaneDevelopment from "./pages/Departments/DeptCane/DeptCaneDevelopment";
import Computer from "./pages/Departments/Computer/Computer";
import CoGeneration from "./pages/Departments/CoGeneration/CoGeneration";
import Distillery from "./pages/Departments/Distillery/Distillery";
import ESL from "./pages/Departments/ESL/ESL";
import Civil from './pages/Departments/Civil/Civil';
import MajorProducts from "./pages/Products/MajorProducts";
import BioProducts from "./pages/Products/BioProducts";
import Awards from "./pages/Awards/Awards";
import FarmerInfo from "./pages/FarmerDetails/FarmerInfo";
import KrantiGallery from "./pages/KrantiGallery/KrantiGallery";
import Tenders from "./pages/Tenders/Tenders";
import OfflineTender from "./pages/Tenders/OfflineTender";
import FarmerDetails from "./pages/FarmerDetails/FarmerDetails";
import TenderLogin from './pages/TenderLogin/TenderLogin';
import TenderRegister from './pages/TenderLogin/TenderRegister';
import ResetLink from "./pages/TenderLogin/ForgetPassword/ResetLink";
import NewPassword from "./pages/TenderLogin/ForgetPassword/NewPassword";
import SucessReset from "./pages/TenderLogin/ForgetPassword/SucessReset";
import TenderApply from "./pages/Tenders/TenderApply";
import TenderSuccess from "./pages/Tenders/TenderSuccess";
import NewsRoom from "./pages/NewsRoom/NewsRoom";
import ContactUs from './pages/Contact/ContactUs';
import PageHeader from "./pages/Header";
import { getAssetPath } from './services/helpers';
import ScrollToTop from './ScrollToTop';
import Engineering from "./pages/Engineering/Engineering";

import { useTranslation } from "react-i18next"


function App() {

  const { t } = useTranslation();

  const { heading , headQuarters, quikLinks1, quikLinks2, getInTouch } = t("footer")

  return (
    <Router>
      <div className="App">
        <ScrollToTop />
       <PageHeader/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home2" element={<Home2 />} />
          <Route path="/AboutUs" element={<AboutUs/>}/>
          <Route path="/OurTeam" element={<OurTeam/>}/>
          <Route path="/CaneDevelopment" element={<CaneDevelopment/>}/>
          <Route path="/OtherFacility" element={<OtherFacility/>}/>
          <Route path="/Administration" element={<Administration/>}/>
          <Route path="/Accounts" element={<Accounts/>}/>
          <Route path="/Manufacturing" element={<Manufacturing/>}/>
          <Route path="/Agriculture" element={<Agriculture/>}/>
          <Route path="/Labortime" element={<Labortime/>}/>
          <Route path="/DeptCaneDevelopment" element={<DeptCaneDevelopment/>}/>
          <Route path="/Computer" element={<Computer/>}/>
          <Route path="/CoGeneration" element={<CoGeneration/>}/>
          <Route path="/Distillery" element={<Distillery/>}/>
          <Route path="/ESL" element={<ESL/>}/>
          <Route path="/Civil" element={<Civil/>}/>
          <Route path="/MajorProducts" element={<MajorProducts/>}/>
          <Route path="/BioProducts" element={<BioProducts/>}/>
          <Route path="/Awards" element={<Awards/>}/>
          <Route path="/FarmerInfo" element={<FarmerInfo/>}/>
          <Route path="/KrantiGallery" element={<KrantiGallery/>}/>
          <Route path="/NewsRoom" element={<NewsRoom/>}/>
          <Route path="/Tenders" element={<Tenders />} />
          <Route path="/OfflineTender" element={<OfflineTender />} />
          <Route path="/FarmerDetails" element={<FarmerDetails/>} />
          <Route path="/TenderLogin" element={<TenderLogin />} />
          <Route path="/ResetLink" element={<ResetLink />} />
          <Route path="/NewPassword/:token" element={<NewPassword />} />
          <Route path="/SucessReset" element={<SucessReset />} />
          <Route path="/TenderRegister" element={<TenderRegister />} />
          <Route path="/Tenders/TenderApply/:tenderId" element={<TenderApply />} />
          <Route path="/Tenders/TenderSuccess" element={<TenderSuccess />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Engineering" element={<Engineering />} />
        </Routes>

        <footer className="page-footer unique-color-dark">
        <div className="container text-md-left">
          <div className="col-md-12 subscribe-quote">
            <div className="">
              <h2 className="footer-quote font-bold">
                {heading.heading}
              </h2>
            </div>
            {/* <div className="subscribe-letter">
                <p className="font-bold">{heading.subText}</p>
              <div className="subscribe-input">
                <div className="input-sub">
                  <input placeholder={inputBtn.placeholder} id="email" name="emai" className="form-control rounded-0 font-regular w-100" type="text" ></input>
                </div>
                  <button className="btn btn-default btn-round learn-more-2 sub-btn">{inputBtn.btn}</button>
              </div>
            </div> */}
          </div>
          <div className="row mt-3 pd-60">
            <div className="col-md-3 mx-auto mb-4 footer-left-section" style={{textAlign:"initial", paddingRight: "40px"}}>
              <img className="footer-logo" alt="footer-logo" src={getAssetPath("assets/imgs/kranti-header-white.png")}></img>
                <p>{headQuarters.title}</p>
            </div>
            <div className="col-md-2 mx-auto mb-4 footer-left-section">
              <div className="link-header">
                  <h6 className="font-bold">{quikLinks1.title}</h6>
              </div>
              <p className="footer-links font-regular">
                <img className="footer-arrow" alt="footer-arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                <a href="/">{quikLinks1.link1}</a>
              </p>
              <p className="footer-links font-regular">
                <img className="footer-arrow" alt="footer-arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                <a href={getAssetPath("AboutUs")}>{quikLinks1.link2}</a>
              </p>
              <p className="footer-links font-regular">
                <img className="footer-arrow" alt="footer-arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                <a href={getAssetPath("OurTeam")}>{quikLinks1.link3}</a>
              </p>
              <p className="footer-links font-regular">
                <img className="footer-arrow" alt="footer-arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                <a href={getAssetPath("CaneDevelopment")}>{quikLinks1.link4}</a>
              </p>
              <p className="footer-links font-regular">
                <img className="footer-arrow" alt="footer-arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                <a href={getAssetPath("Administration")}>{quikLinks1.link5}</a>
              </p>
            </div>
            <div className="col-md-2 mx-auto mb-4 footer-left-section" >
              <div className="link-header">
                  <h6 className="font-bold">{quikLinks2.title}</h6>
                </div>
                <p className="footer-links font-regular">
                  <img className="footer-arrow" alt="footer-arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                  <a href={getAssetPath("MajorProducts")}>{quikLinks2.link1}</a>
                </p>
                <p className="footer-links font-regular">
                  <img className="footer-arrow" alt="footer-arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                  <a href={getAssetPath("Awards")}>{quikLinks2.link2}</a>
                </p>
                <p className="footer-links font-regular">
                  <img className="footer-arrow" alt="footer-arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                  <a href={getAssetPath("FarmerInfo")}>{quikLinks2.link3}</a>
                </p>
                <p className="footer-links font-regular">
                  <img className="footer-arrow" alt="footer-arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                  <a href={getAssetPath("ContactUs")}>{quikLinks2.link4}</a>
                </p>
            </div>
            <div className="col-md-3 mx-auto mb-4 footer-left-section" >
              <div className="link-header">
                  <h6 className="font-bold">{getInTouch.heading}</h6>
              </div>
              <div className="footer-links location-link font-regular">
                <img className="c-info" alt="c-info" src={getAssetPath("assets/icons/location.png")}></img>
                  <p>{getInTouch.para1}</p>
              </div>
              <div className="footer-links font-regular">
                <img className="c-info" alt="c-info" src={getAssetPath("assets/icons/new-call-footer.png")}></img>
                <p>{getInTouch.para2}</p>
              </div>
              <div className="footer-links font-regular">
                <img className="c-info" alt="c-info" src={getAssetPath("assets/icons/new-mail-footer.png")}></img>
                <a href={`mailto:${getInTouch.para3}`}>
                {getInTouch.para3}
                </a>
              </div>
              <div className="footer-links font-regular">
                <img className="c-info" alt="c-info" src={getAssetPath("assets/icons/new-clock-footer.png")}></img>
                <p>{getInTouch.para4}</p>
              </div>
              <div className="footer-social-links">
                <a href='https://www.facebook.com/people/%E0%A4%95%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%82%E0%A4%A4%E0%A5%80%E0%A4%85%E0%A4%97%E0%A5%8D%E0%A4%B0%E0%A4%A3%E0%A5%80-%E0%A4%AA%E0%A4%B0%E0%A4%BF%E0%A4%B5%E0%A4%BE%E0%A4%B0/pfbid0oKWyV72a5h7YtNwwatpA7HK8hAvJS3u4JGvLRjnomshdw4Rzdvq7Teqjcof9TbZzl/'>
                  <img className="footer-icon"  alt="footer-fb" src={getAssetPath("assets/icons/footer-fb.png")}></img>
                </a>
                <a href='https://x.com/krantiagranipa1'>
                  <img className="footer-icon"  alt="footer-x" src={getAssetPath("assets/icons/footer-x.png")}></img>
                </a>
                <a href='https://www.youtube.com/channel/UC5KBHhwyVLLCT1nvrSEBWVw'>
                  <img className="footer-icon"  alt="footer-yt" src={getAssetPath("assets/icons/footer-yt.png")}></img>
                </a>
                <a href='mailto:md@krantisugar.com '>
                  <img className="footer-icon"  alt="footer-msg" src={getAssetPath("assets/icons/footer-msg.png")}></img>
                </a>
              </div>
            </div>
            <div className="col-md-2 mx-auto mb-4 footer-left-section" style={{textAlign:"initial"}}>
              <img className="footer-logo" alt="footer-bapulad" style={{marginBottom : "15px"}} src={getAssetPath("assets/imgs/footer-bapulad.png")}></img>
              <p>{headQuarters.title1}</p>
            </div>
          </div>
          <div className="footer-copyright-bg">
            <div className="row">
              <p className="col-md-12 footer-copyright text-center font-regular">
              {getInTouch.para5}
              </p>
            </div>
          </div>
        </div>
      </footer>
      </div>
    </Router>
  );
}

export default App;
