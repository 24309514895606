import React, { useState } from "react";
import "./Civil.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";
import { Trans, useTranslation } from "react-i18next";
import { imageNames } from "../../Departments/Civil/staticImageArray";


const Civil = () => {

    const { t } = useTranslation();
    const {containerHeading,containerHeading1,breadcrumbItem1,breadcrumbItem2,breadcrumbItem3,containerPara1,containerPara2,containerPara3,containerPara4,containerPara5,containerPara6,containerPara7,subtitle,titlePara,list,imageTitle,containerimg1,containerimg2,containerimg3,containerimg4,containerimg5,containerimg6,containerimg7} = t('civil',  { returnObjects: true })

    var CivilImgs = {
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        fade: true,
        cssEase: "linear",
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    
      }

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageTitle, setSelectedImageTitle] = useState(null);
    const openModal = (imageName) => {
        setSelectedImage(imageName.thumbnail);
        setSelectedImageTitle(imageTitle[imageName.title]);
    }

    return(
        <>
        <div className="Tenders-headbanner">
            <div className="distillery-banner col-md-12">
            <div className="container">
                <h1 className="headingtenders font-bold">{containerHeading}</h1>
                <div className="breadcrum">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-container">
                    <li className="breadcrumb-item brdm-itm">
                        <a href="/home">{breadcrumbItem1}</a>
                    </li>
                    <li className="breadcrumb-item brdm-itm">
                        <a href="/home">{breadcrumbItem2}</a>
                    </li>
                    <li
                        className="breadcrumb-item brdm-itm active"
                        aria-current="page"
                    >
                        {breadcrumbItem3}
                    </li>
                    </ol>
                </nav>
                </div>
            </div>
            </div>
        </div>

        <section className="ngfc-bg">
            <div className="container">
                <div className="newsroom">
                    <div className="row mrg-b d-flex justify-content-center">
                        <div className="col-md-6">
                            <h2 className="investor-title mb-4">{containerHeading}</h2>
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara1}
                            components={{1: <br/> }}></Trans>
                            </p>
                            <br />
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara2}
                            components={{1: <br/> }}></Trans>
                            </p>
                        </div>
                        <div className="col-md-6 img-flex">
                            {/* <div className="agriculture-multimg">
                                <Slider {...CivilImgs}>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Falling-Film-Evaporator-Top.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg1}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Falling-Film-Evaporator-Set.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg2}</p>
                                            </div>
                                        </div>                                       
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Countinious-Pan.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg3}</p>
                                            </div>
                                        </div>                
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Hopper.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg4}</p>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Batch-Pan.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg5}</p>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Centrifugal-Machine.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg6}</p>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Juice-Heater.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg7}</p>
                                            </div>
                                        </div>                                        
                                    </div>
                                </Slider>
                            </div> */}
                            <img className="department-img" alt="civil" src={getAssetPath("assets/imgs/department/civil/civil-img1.png")}></img>
                        </div>
                    </div>
                    <div className="row mrg-b d-flex justify-content-center">
                        <div className="col-md-6 img-flex-menu">
                            <img className="department-img" alt="civil" src={getAssetPath("assets/imgs/department/civil/civil-img2.png")}></img>
                        </div>
                        <div className="col-md-6">
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara3}
                            components={{1: <br/> }}></Trans>
                            </p>
                            <br />
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara4}
                            components={{1: <br/> }}></Trans>
                            </p>
                            <br />
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara5}
                            components={{1: <br/> }}></Trans>
                            </p>
                        </div>                        
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara6}
                            components={{1: <br/> }}></Trans>
                            </p>
                            <br />
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara7}
                            components={{1: <br/> }}></Trans>
                            </p>
                        </div>
                        <div className="col-md-6 img-flex">
                            {/* <div className="agriculture-multimg">
                                <Slider {...CivilImgs}>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Falling-Film-Evaporator-Top.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg1}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Falling-Film-Evaporator-Set.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg2}</p>
                                            </div>
                                        </div>                                       
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Countinious-Pan.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg3}</p>
                                            </div>
                                        </div>                
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Hopper.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg4}</p>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Batch-Pan.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg5}</p>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Centrifugal-Machine.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg6}</p>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="img-item civil-img">
                                        <img
                                            className="department-img"
                                            alt="civil"
                                            src={getAssetPath("assets/imgs/department/civil/Juice-Heater.jpeg")}
                                        ></img>
                                        <div className="image-name">
                                            <div className="container">
                                                <p className="department-sbtxt ">{containerimg7}</p>
                                            </div>
                                        </div>                                        
                                    </div>
                                </Slider>
                            </div> */}
                            <img className="department-img" alt="civil" src={getAssetPath("assets/imgs/department/civil/civil-img3.png")}></img>
                        </div>
                    </div>


                    {/* <div className="row">
                        <div className="col-md-6">
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara2}
                            components={{1: <br/> }}></Trans>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara3}
                            components={{1: <br/> }}></Trans>
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>

        <section className="tender-table" style={{backgroundColor : "#f6f6f6"}}>
            <div className="container">
                <div className="">
                    <div className="row mb-2 mt-2 d-flex justify-content-center">
                        <div className="col-md-6 img-flex-manu">
                            <img className="department-img upsa-img" alt="civil" src={getAssetPath("assets/imgs/department/civil/civil-img10.jpeg")}></img>
                        </div>
                        <div className="col-md-6">
                            <h2 className="investor-title mb-3"  style={{fontSize : "32px"}}>{subtitle}</h2>
                            <div className="bt-text font-regular mb-3">
                                <p>
                                {titlePara}
                                </p>
                            </div>
                            <div className="bt-text font-regular mb-3">
                                <p>
                                <Trans
                            i18nKey={list}
                            components={{1: <br/> }}></Trans>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="ngfc-bg">
            <div className="container">
                <div className="newsroom">
                <h2 className="investor-title mb-4">{containerHeading1}</h2>
                    <div className="row">
                    {imageNames.map((imageName, index) => (
                        <div className="col-md-4">
                            <div className="deve-facility">
                                <div className="cane-facility-img" data-bs-toggle="modal" data-bs-target="#Modalimg" key={index}  onClick={() => openModal(imageName)}>
                                    <img className="img-fluid gallery-img" src={imageName.thumbnail} alt={`civil ${index + 1}`} />
                                    <p className="department-sbtxt  txt-overflw mt-2">{imageTitle[imageName.title]}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </section>

        <div className="modal fade" id="Modalimg" tabIndex="-1" aria-labelledby="Modalimg" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <a href="/" className="close" data-bs-dismiss="modal">
                            <img className="img-fluid" alt="close-modal" src={getAssetPath("assets/icons/close-modal.png")}></img>
                        </a>
                        <div className="row">
                            <div className="col-md-8" style={{margin : "0 auto"}}>
                                <div className="leadership-modal-img civil-img">
                                    <img className="img-fluid ks-gallery-img w-100" alt="civil" src={selectedImage}></img>
                                    <div className="image-name">
                                        <div className="container">
                                            <p className="department-sbtxt ">{selectedImageTitle}</p>
                                        </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )



};
export default Civil