    // start download tender
    export const downloadClick = async (url , fileName) => {
        try {
          // Fetch the file from the URL
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // Create a Blob from the response
          const blob = await response.blob();
          // Create a Blob URL
          const blobUrl = window.URL.createObjectURL(blob);
          // Create an anchor element and set the href attribute to the Blob URL
          const link = document.createElement('a');
          link.href = blobUrl;
          // Set the download attribute with the filename
          link.setAttribute('download', fileName);
          // Append the link to the body
          document.body.appendChild(link);
          // Programmatically click the link to trigger the download
          link.click();
          // Clean up by removing the link and revoking the Blob URL
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
          console.error('Error downloading the file:', error);
        }
    }
    // end download tender
