import React from "react";
import { useState, useRef } from "react";
import "./MajorProducts.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";

const MajorProducts = () => {
  const { t } = useTranslation();

  const { banner, newsRoom, overview } = t("majorProducts");

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  //products mobile slider btn
  let mobRef = useRef(null);
  const mobnext = () => {
    mobRef.slickNext();
  };
  const mobprevious = () => {
    mobRef.slickPrev();
  };

  var leadershipslider = {
    autoplay: false,
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
        },
      },
    ],
  };
  var leadershipmobslider = {
    autoplay: false,
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="majorproduct-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="simple-idea abt-pdtop">
        <div className="container">
          <div className="row ">
            <div className="container col-md-5">
              <h2 className="idea-title">{newsRoom.heading}</h2>
            </div>
            <div className="container col-md-10">
              <p className="idea-para abt-para-mrg">{newsRoom.para}</p>
            </div>
          </div>
        </div>
        <img
          className="simpleidea-img prd-rads"
          alt="sugar"
          src={getAssetPath("assets/imgs/products/whitebrown-sugar.png")}
        ></img>
      </section>

      <section className="company-overview">
        {/* desktop-view */}
        <div className="leadership-desktop-view">
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...leadershipslider}
          >
            <div className="cogeneration-over-wrp">
              <div className="container leadership-details">
                <div className="col-md-6">
                  <h2 className="quote font-bold">
                    {overview.desktop.slider1.title}
                  </h2>
                  <p className="description ">
                    {overview.desktop.slider1.text}
                  </p>
                </div>
              </div>
            </div>
            <div className="sugar-over-wrp">
              <div className="container leadership-details">
                <div className="col-md-6">
                  <h2 className="quote font-bold">
                    {overview.desktop.slider2.title}
                  </h2>
                  <p className="description ">
                    {overview.desktop.slider2.text}
                  </p>
                </div>
              </div>
            </div>
            <div className="distillery-over-wrp">
              <div className="container leadership-details">
                <div className="col-md-6">
                  <h2 className="quote font-bold">
                    {overview.desktop.slider3.heading}
                  </h2>
                  <ul className="distilery-list">
                    <li>{overview.desktop.slider3.para1}</li>
                    <li>{overview.desktop.slider3.para2}</li>
                    <li>{overview.desktop.slider3.para3}</li>
                    <li>{overview.desktop.slider3.para4}</li>
                    <li>{overview.desktop.slider3.para5}</li>
                    <li>{overview.desktop.slider3.para6}</li>
                    <li>{overview.desktop.slider3.para7}</li>
                    <li>{overview.desktop.slider3.para8}</li>
                    <li>{overview.desktop.slider3.para9}</li>
                    <li>{overview.desktop.slider3.para10}</li>
                    <li>{overview.desktop.slider3.para11}</li>
                    <li>{overview.desktop.slider3.para12}</li>
                  </ul>
                </div>
              </div>
            </div>
          </Slider>
          <div className="btns prdcts-btns" style={{ textAlign: "right" }}>
            <button className="prev-btn" onClick={previous}>
              <img
                className="slick-arrow-prev"
                alt="slick-arrow"
                src={getAssetPath("assets/icons/Slick-arrow.png")}
              ></img>
            </button>
            <button className="nxt-btn" onClick={next}>
              <img
                className="slick-arrow-nxt"
                alt="slick-arrow"
                src={getAssetPath("assets/icons/Slick-arrow.png")}
              ></img>
            </button>
          </div>
        </div>

        {/* mobile-view */}
        <div className="leadership-mobile-view">
          <div className="container">
            <div className="col-md-12">
              <Slider
                ref={(slider) => {
                  mobRef = slider;
                }}
                {...leadershipmobslider}
              >
                <div className="facility-card">
                  <div className="facility-main-card">
                    <div className="facility-img">
                      <img
                        className="mobile-facilityimg"
                        alt="product-1"
                        src={getAssetPath("assets/imgs/products/cogene1.jpg")}
                      ></img>
                    </div>
                  </div>
                  <div className="facility-content leadership-content">
                    <div className="leadership-quote">
                      <h4 className="font-semibold">{overview.mobile.slider1.title}</h4>
                    </div>
                    <div className="leadership-preview">
                      <p className="font-regular">
                       {overview.mobile.slider1.text}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="facility-card">
                  <div className="facility-main-card">
                    <div className="facility-img">
                      <img
                        className="mobile-facilityimg"
                        alt="product-2"
                        src={getAssetPath("assets/imgs/products/sugar.png")}
                      ></img>
                    </div>
                  </div>
                  <div className="facility-content leadership-content">
                    <div className="leadership-quote">
                      <h4 className="font-semibold">{overview.mobile.slider2.title}</h4>
                    </div>
                    <div className="leadership-preview">
                      <p className="font-regular">
                      {overview.mobile.slider2.text}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="facility-card">
                  <div className="facility-main-card">
                    <div className="facility-img">
                      <img
                        className="mobile-facilityimg"
                        alt="product-2"
                        src={getAssetPath("assets/imgs/products/dist1.jpg")}
                      ></img>
                    </div>
                  </div>
                  <div className="facility-content leadership-content">
                    <div className="leadership-quote">
                      <h4 className="font-semibold">{overview.mobile.slider3.heading}</h4>
                    </div>
                    <div className="leadership-preview">
                      <ul className="mob-distilery-list">
                        <li>{overview.mobile.slider3.para1}</li>
                        <li>{overview.mobile.slider3.para2}</li>
                        <li>
                        {overview.mobile.slider3.para3}
                        </li>
                        <li>{overview.mobile.slider3.para4}</li>
                        <li>{overview.mobile.slider3.para5}</li>
                        <li>{overview.mobile.slider3.para6}</li>
                        <li>
                        {overview.mobile.slider3.para7}
                        </li>
                        <li>{overview.mobile.slider3.para8}</li>
                        <li>
                        {overview.mobile.slider3.para9}
                        </li>
                        <li>
                        {overview.mobile.slider3.para10}
                        </li>
                        <li>
                        {overview.mobile.slider3.para11}
                        </li>
                        <li>
                        {overview.mobile.slider3.para12}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div className="btns" style={{ textAlign: "center" }}>
            <button className="prev-btn" onClick={mobprevious}>
              <img
                className="slick-arrow-prev"
                alt="slick-arrow"
                src={getAssetPath("assets/icons/Slick-arrow.png")}
              ></img>
            </button>
            <button className="nxt-btn" onClick={mobnext}>
              <img
                className="slick-arrow-nxt"
                alt="slick-arrow"
                src={getAssetPath("assets/icons/Slick-arrow.png")}
              ></img>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default MajorProducts;
