import React from "react";
import "./FarmerInfo.css";
import { getAssetPath } from "../../services/helpers";
import { useTranslation ,Trans } from "react-i18next";

const FarmerInfo = () => {
  const { t } = useTranslation();
  const { bannerInfo, container, companyOverview } = t("farmerCorner");


  return (
    <>
      <div className="">
        <div className="Farmer-banner-comingsoon col-md-12">
          <div className="container mt-3">
            <div className="comingsoon-container">
              <img className="img-fluid" src={getAssetPath("assets/imgs/Coming-Soon.png")} alt="coming-soon"></img>
            </div>
            <h4 className="comingsoon-header font-medium">{bannerInfo.heading}<br/>{bannerInfo.heading2}</h4>

            <div className="view-more d-flex justify-content-center mt-3" style={{zIndex : 99}}> 
              <a href={getAssetPath('')}>
                  <button className="btn btn-default btn-round learn-more-2 ">{bannerInfo.btn}
                      <img className="Arrow-1" alt="arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                  </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FarmerInfo;
