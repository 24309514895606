import React, { useState } from "react";
import "./ContactUs.css";
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";
import { useContactUsQueryMutation } from "../../services/userAuthApi";
import i18next from "i18next";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ContactUs = () => {
  const { t } = useTranslation();
  const { banner, info, address , requiredMsg} = t("contact");

  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .matches(/^[a-zA-Z ]+$/, 'Name must contain only alphabets'),
    email: Yup.string()
      .required('Email is required')
      .matches(new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), "Please enter valid email")
      .email('Invalid email address'),
    mobile: Yup.string()
      .required('Mobile number is required')
      .matches(/^[0-9]{10}$/, 'Invalid mobile number'),
    description: Yup.string()
      .required('Description is required'),
  });
  const [contactUsUser] = useContactUsQueryMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setError("");
    setSuccess("");
    setIsLoading(true);
    const formData = {
      ...values,
      subject: values.subject || 'Website Enquiry',
      lang: i18next.language,
    };

    if (
      formData.name &&
      formData.email &&
      formData.mobile &&
      formData.description
    ) {
      const res = await contactUsUser(formData);
      if (res.data && res.data.message === "success") {
        setSuccess(res.data.data);
        resetForm();
      }
      if (res.error) {
        if (
          Array.isArray(res.error.data.message) &&
          res.error.data.message.length > 1
        ) {
          const errorMsg = (
            <ul>
              {res.error.data.message.map((msg, index) => (
                <li key={index}>{msg}</li>
              ))}
            </ul>
          );
          setError({ status: true, msg: errorMsg, type: "error" });
        } else {
          setError({
            status: true,
            msg: res.error.data.message,
            type: "error",
          });
        }
      }
    } else {
      setError({ status: true, msg: requiredMsg, type: "error" });
    }
    setIsLoading(false);
    setSubmitting(false);
  };

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="contact-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="git">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="contact-heading">
                <h2>{info.heading}</h2>
              </div>
              {error.status ? (
                  <div className="alert alert-danger">{error.msg}</div>
                ) : (
                  ""
                )}
                {success && (
                  <div className="alert alert-success">{success}</div>
                )}
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    mobile: '',
                    subject: '',
                    description: ''
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                {({ isSubmitting, isValid }) => (
              <Form id="contact-uS-form">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 inpt-mrg">
                    <div className="input-grp cntc-inpt">
                       <Field
                        placeholder={info.text1}
                        id="name"
                        name="name"
                        className="form-control rounded-0 font-regular"
                        type="text"
                      />
                    </div>
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 inpt-mrg">
                    <div className="input-grp cntc-inpt">
                        <Field
                        placeholder={info.text2}
                        id="email"
                        name="email"
                        className="form-control rounded-0 font-regular"
                        type="text"
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 inpt-mrg">
                    <div className="input-grp cntc-inpt">
                        <Field
                        placeholder={info.text3}
                        id="mobile"
                        name="mobile"
                        className="form-control rounded-0 font-regular"
                        type="number"
                      />
                    </div>
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 inpt-mrg">
                    <div className="input-grp cntc-inpt">
                      <Field
                      placeholder={info.text4}
                      id="subject"
                      name="subject"
                      className="form-control rounded-0 font-regular"
                      type="text"
                    />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 inpt-mrg">
                    <div className="textarea-grp">
                        <Field
                        placeholder={info.text5}
                        className="form-control font-regular"
                        id="description"
                        name="description"
                        rows="10"
                        as="textarea"
                      />
                    </div>
                    <ErrorMessage
                        name="description"
                        component="div"
                        className="error-message"
                      />
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <button  className="btn btn-default btn-round learn-more-2 contact-btn" type="submit" disabled={isSubmitting || !isValid}>
                    {isLoading ? (
                      <div className="loader">Please wait ..</div>
                    ) : (
                      info.submit
                    )}
                  </button>
                  </div>
                </div>
              </div>
              </Form>
            )}
          </Formik>
            </div>
            <div className="col-md-5 cnt-pd-lft">
              <div className="address-heading">
                              <h2>{address.heading}</h2>
                <p className="page-subheading mar-btm-50">
                  {address.text1}
                </p>
              </div>
              <div className="address-info">
                <div className="contact-info-address">
                  <img
                    className="img-fluid info-icon"
                    alt="contact-info"
                    src={getAssetPath("assets/icons/contact-location.png")}
                  ></img>
                  <div className="info">
                    <h6>{address.text2}</h6>
                    <p>{address.text3}</p>
                  </div>
                </div>
                <div className="contact-info-address">
                  <img
                    className="img-fluid info-icon"
                    alt="contact-info"
                    src={getAssetPath("assets/icons/contact-mail.png")}
                  ></img>
                  <div className="info">
                    <p>{address.mail1}</p>
                    <p>{address.mail2}</p>
                  </div>
                </div>
                <div className="contact-info">
                  <img
                    className="img-fluid info-icon"
                    alt="contact-info"
                    src={getAssetPath("assets/icons/contact-phone.png")}
                  ></img>
                  <div className="info">
                    <p>{address.text6}</p>
                  </div>
                </div>
                <div className="follow-us">
                  <p>{address.text7}</p>
                  <div className="social-links">
                    <a href="https://www.youtube.com/channel/UC5KBHhwyVLLCT1nvrSEBWVw">
                      <img
                        className="social-icon"
                        alt="yt"
                        src={getAssetPath("assets/icons/leadership-yt.png")}
                      ></img>
                    </a>
                    <a href="https://www.facebook.com/people/%E0%A4%95%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%82%E0%A4%A4%E0%A5%80%E0%A4%85%E0%A4%97%E0%A5%8D%E0%A4%B0%E0%A4%A3%E0%A5%80-%E0%A4%AA%E0%A4%B0%E0%A4%BF%E0%A4%B5%E0%A4%BE%E0%A4%B0/pfbid0oKWyV72a5h7YtNwwatpA7HK8hAvJS3u4JGvLRjnomshdw4Rzdvq7Teqjcof9TbZzl/">
                      <img
                        className="social-icon"
                        alt="fb"
                        src={getAssetPath("assets/icons/fb.png")}
                      ></img>
                    </a>
                    <a href="https://x.com/krantiagranipa1">
                      <img
                        className="social-icon"
                        alt="x"
                        src={getAssetPath("assets/icons/x.png")}
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="google-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7625.437381325555!2d74.42714200000002!3d17.135216!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc16de9c416e313%3A0xa9228debf1c2ff8a!2sKranti%20Sugar%20factory!5e0!3m2!1sen!2sin!4v1718092231052!5m2!1sen!2sin"
            width="100%"
            height="600"
            title="google-map"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
