import React, { useState } from "react";
import "./ResetLink.css";
import { useParams,useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../../services/userAuthApi";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const NewPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { heading, backToLogin, btnText, passwordPlaceholder1, passwordPlaceholder2 } = t("newPassword");

  const { token } = useParams();
  const [resetPassword] = useResetPasswordMutation(); // resetPassword
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    const formData = new FormData();
    formData.append("token", token);
    formData.append("new_password", password);
    formData.append("confirm_password", confirmPassword);
    formData.append("lang", i18next.language);

    try {
      const response = await resetPassword({ formData });
      if (response.error) {
        if (
          Array.isArray(response.error.data.message) &&
          response.error.data.message.length > 1
        ) {
          setError(
            <ul>
              {response.error.data.message.map((msg, index) => (
                <li key={index}>{msg}</li>
              ))}
            </ul>
          );
        } else {
          setError(response.error.data.message);
        }
      } else {
        setSuccess(response.data.message);
        navigate("/SucessReset");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    }
  };
  return (
    <>
      <section className="login-bg">
        <div className="slider-login fslogin-bg">
          <div className="login-content">
            <div className="container">
              <div className="container login-form-row col-md-6 mg-tp">
                <p className="col-12 txt-center font-bold">
                 {heading}
                </p>
                {error && <div className="alert alert-danger">{error}</div>}
                {success && (
                  <div className="alert alert-success">{success}</div>
                )}
                <div className="tab-content custom-tb-cnt" id="myTabContent">
                  <form onSubmit={handleSubmit}>
                    <div
                      className="tab-pane fade show active"
                      id="Supplier"
                      role="tabpanel"
                      aria-labelledby="Supplier-tab"
                    >
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          alt="icon"
                          src={getAssetPath("assets/icons/password-icon.png")}
                        ></img>
                        <input
                          placeholder={passwordPlaceholder1}
                          id="new_password"
                          name="new_password"
                          className="form-control rounded-0 font-regular"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-round show-hide-btn"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <img
                              className="show-pass"
                              alt="icon"
                              src={getAssetPath("assets/icons/show-pass.png")}
                            />
                          ) : (
                            <img
                              className="hide-pass"
                              alt="icon"
                              src={getAssetPath("assets/icons/hide-pass.png")}
                            />
                          )}
                        </button>
                      </div>
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          alt="icon"
                          src={getAssetPath("assets/icons/password-icon.png")}
                        ></img>
                        <input
                          placeholder={passwordPlaceholder2}
                          id="confirm_password"
                          name="confirm_password"
                          className="form-control rounded-0 font-regular"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-round show-hide-btn"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? (
                            <img
                              className="show-pass"
                              alt="icon"
                              src={getAssetPath("assets/icons/show-pass.png")}
                            />
                          ) : (
                            <img
                              className="hide-pass"
                              alt="icon"
                              src={getAssetPath("assets/icons/hide-pass.png")}
                            />
                          )}
                        </button>
                      </div>
                      <label className="mb-3">
                        <a href="/TenderLogin">
                          {backToLogin}
                        </a>
                      </label>
                        <button className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold">
                         {btnText}
                          <img
                            className="Arrow-1"
                            alt="icon"
                            src={getAssetPath("assets/icons/Arrow-1.png")}
                          ></img>
                        </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewPassword;
