import React, { useEffect, useState } from "react";
import "./FarmerDetails.css";
import {
  useGetLoggedUserQuery,
  useGetPagesAccessQuery,
  useLogoutQueryMutation,
} from "../../services/userAuthApi";
import {
  getLocalStorageData,
  removeLocalStorageData,
} from "../../services/localStorageService";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";

const FarmerDetails = () => {
  const { t } = useTranslation();

  const { banner, tenderTable } = t("farmerDetails");

  const navigate = useNavigate();
  if (!getLocalStorageData("token") && getLocalStorageData("roleId") !== 1) {
    navigate("/TenderLogin");
  }
  const token = getLocalStorageData("token");

  //start access page based on Role id
  const [hasPageAccess, setPageAccess] = useState(false);
  const { data: pageAccess, isSuccess: getAccessSuccess } =
    useGetPagesAccessQuery({ token });
  useEffect(() => {
    if (getAccessSuccess) {
      const pathname = window.location.pathname;
      const pageExists = pageAccess.some((page) => pathname.includes(page));
      setPageAccess(pageExists);
    }
  }, [pageAccess, getAccessSuccess]);
  //end access page based on Role id

  const [searchTerm, setSearchTerm] = useState("");
  const [logoutUser] = useLogoutQueryMutation();
  const { data, isSuccess } = useGetLoggedUserQuery({ token });
  const handleLogout = async () => {
    const res = await logoutUser({ token });
    if (res.data.message === "success") {
      removeLocalStorageData();
      navigate("/TenderLogin");
    }
  };
  const [farmerData, setFarmerData] = useState("No Record");

  //pagination
  const [pageNumber, setPageNumber] = useState(0); //when click which page
  const salesPerPage = 10;
  const pageVisited = pageNumber * salesPerPage;
  const displaySales =
    farmerData !== "No Record" &&
    farmerData
      .slice(pageVisited, pageVisited + salesPerPage)
      .filter((item) => {
        return searchTerm.toString() === ""
          ? item
          : item.rate_per_kg.toString().includes(searchTerm) ||
              item.sold_out_qtl.toString().includes(searchTerm) ||
              item.amount.toString().includes(searchTerm) ||
              item.created_at.toString().includes(searchTerm);
      })
      .map((farmer, index) => {
        return (
          <tr className="tr-height" key={index + 1}>
            <td>{farmer.created_at}</td>
            <td>{farmer.rate_per_kg}</td>
            <td>{farmer.sold_out_qtl}</td>
            <td className="green">{farmer.amount}</td>
          </tr>
        );
      });
  const pageCount = Math.ceil(farmerData.length / salesPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  //pagination end

  useEffect(() => {
    if (data && isSuccess) {
      const response = data.data;
      setFarmerData(response);
    }
  }, [data, isSuccess]);

  return (
    <>
      {hasPageAccess ? (
        <div>
          <div className="Tenders-headbanner">
            <div className="Farmer-banner col-md-12">
              <div className="container">
                <h1 className="headingtenders font-bold">{banner.heading}</h1>
                <div className="breadcrum">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-container">
                      <li className="breadcrumb-item brdm-itm">
                        <a href="/home">{banner.link}</a>
                      </li>
                      <li
                        className="breadcrumb-item brdm-itm active"
                        aria-current="page"
                      >
                        {banner.text}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <section className="tender-table">
            <div className="">
              <div className="tendermain-table">
                <div className="col-md-12">
                  <div className="login-search">
                    <div className="farmer-name">
                      <div className="username">
                        <h2 className="font-bold">
                          {tenderTable.welcome}{" "}
                          <span>{tenderTable.kisan} </span>
                        </h2>
                        <button
                          className="btn btn-default btn-round tender-Register"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalCenter"
                        >
                          <img
                            className="back"
                            alt="back"
                            src={getAssetPath("assets/icons/logout.png")}
                          ></img>
                        </button>
                      </div>
                      <p className="appl-text regular-font">
                        {tenderTable.para}
                      </p>
                    </div>
                    <div className="input-grp search-inp w-50">
                      <img
                        className="user-icon"
                        alt="icon"
                        src={getAssetPath("assets/icons/search-icon.png")}
                      ></img>
                      <input
                        placeholder={tenderTable.search}
                        id="search"
                        name="search"
                        className="form-control rounded-0 font-regular"
                        type="text"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className="table-responsive mb-3">
                    <table className="table table-striped table-hover ">
                      <thead className="thead-dark th-height">
                        <tr>
                          <th scope="col">{tenderTable.tableHead.date}</th>
                          <th scope="col">
                            {tenderTable.tableHead.sugacaneRate}
                          </th>
                          <th scope="col">
                            {tenderTable.tableHead.sugarcaneSold}
                          </th>
                          <th scope="col">
                            {tenderTable.tableHead.totalAmount}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {displaySales.length === 0 ? (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={4}>
                              <div className="no-record-found">
                                <img
                                  className="not-found-img img-fluid"
                                  alt="not-found"
                                  src={getAssetPath(
                                    "assets/imgs/not-found-img.png"
                                  )}
                                ></img>
                                No record found
                              </div>
                            </td>
                          </tr>
                        ) : (
                          displaySales
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="table-pagination">
                    <p className="appl-text regular-font"></p>

                    <nav aria-label="Page navigation example">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      ></ReactPaginate>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        "Sorry!! does not have permission to access this page"
      )}

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 mr-auto">
                  <div className="confirm-bg">
                    <img
                      className="sucess"
                      alt="sucess"
                      src={getAssetPath("assets/icons/caution.png")}
                    ></img>
                    <h2 className="sucess-title font-bold">Come Back soon!</h2>
                    <p className="sucess-sbtxt font-regular">
                      Are you sure you want to logout?
                    </p>
                    <div className="confirm-btns">
                      <button
                        className="btn btn-default btn-round cancel-logout"
                        data-bs-dismiss="modal"
                      >
                        No
                      </button>
                      <button
                        className="btn btn-default btn-round tender-submit"
                        data-bs-dismiss="modal"
                        onClick={handleLogout}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FarmerDetails;
