import React from "react";
import "./CoGeneration.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";

const CoGeneration = () => {

  const { t } = useTranslation();

  const { banner, newsRoom, tenderTable } = t("CoGeneration");

  var CogenImgs = {
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

  }

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="cogen-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-4 d-flex justify-content-center">
              <div className="col-md-6">
                <h2 className="investor-title mb-4">{newsRoom.heading}</h2>
                <p className="department-sbtxt ">
                  {newsRoom.para}
                </p>
                <br />
                <p className="department-sbtxt ">
                  {newsRoom.para1}
                </p>
                <br />
                <p className="department-sbtxt ">
                  {newsRoom.para2}
                </p>
                <p className="department-sbtxt ">
                  {newsRoom.para3}
                </p>
                <p className="department-sbtxt ">
                  {newsRoom.para4}
                </p>
              </div>
              <div className="col-md-6">
                <div className="agriculture-multimg">
                  <Slider {...CogenImgs}>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/cogen/cogen1.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/cogen/cogen2.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/cogen/cogen3.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/cogen/cogen4.jpg")}
                      ></img>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tender-table">
        <div className="container">
          <div className="tendermain-table">
            <div className="col-md-12">
              <div className="login-search">
                <div className="farmer-name">
                  <div className="username investor-title">
                    <h2 className="font-bold" style={{fontWeight : '600'}}>{tenderTable.heading}</h2>
                  </div>
                </div>
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-striped table-hover ">
                  <thead className="thead-dark th-height">
                    <tr>
                      <th scope="col">{tenderTable.tableHead.text1}</th>
                      <th scope="col">{tenderTable.tableHead.text2}</th>
                      <th scope="col">{tenderTable.tableHead.text3}</th>
                      <th scope="col">{tenderTable.tableHead.text4}</th>
                      <th scope="col">{tenderTable.tableHead.text5}</th>
                      <th scope="col">{tenderTable.tableHead.text6}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row1.srNo}</td>
                      <td>{tenderTable.tenderBody.row1.season}</td>
                      <td>{tenderTable.tenderBody.row1.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row1.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row1.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row1.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row2.srNo}</td>
                      <td>{tenderTable.tenderBody.row2.season}</td>
                      <td>{tenderTable.tenderBody.row2.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row2.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row2.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row2.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row3.srNo}</td>
                      <td>{tenderTable.tenderBody.row3.season}</td>
                      <td>{tenderTable.tenderBody.row3.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row3.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row3.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row3.export}</td>

                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row4.srNo}</td>
                      <td>{tenderTable.tenderBody.row4.season}</td>
                      <td>{tenderTable.tenderBody.row4.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row4.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row4.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row4.export}</td>

                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row5.srNo}</td>
                      <td>{tenderTable.tenderBody.row5.season}</td>
                      <td>{tenderTable.tenderBody.row5.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row5.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row5.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row5.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row6.srNo}</td>
                      <td>{tenderTable.tenderBody.row6.season}</td>
                      <td>{tenderTable.tenderBody.row6.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row6.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row6.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row6.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row7.srNo}</td>
                      <td>{tenderTable.tenderBody.row7.season}</td>
                      <td>{tenderTable.tenderBody.row7.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row7.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row7.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row7.export}</td>

                    </tr>
                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row8.srNo}</td>
                      <td>{tenderTable.tenderBody.row8.season}</td>
                      <td>{tenderTable.tenderBody.row8.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row8.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row8.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row8.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row9.srNo}</td>
                      <td>{tenderTable.tenderBody.row9.season}</td>
                      <td>{tenderTable.tenderBody.row9.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row9.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row9.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row9.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row10.srNo}</td>
                      <td>{tenderTable.tenderBody.row10.season}</td>
                      <td>{tenderTable.tenderBody.row10.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row10.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row10.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row10.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row11.srNo}</td>
                      <td>{tenderTable.tenderBody.row11.season}</td>
                      <td>{tenderTable.tenderBody.row11.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row11.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row11.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row11.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row12.srNo}</td>
                      <td>{tenderTable.tenderBody.row12.season}</td>
                      <td>{tenderTable.tenderBody.row12.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row12.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row12.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row12.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row13.srNo}</td>
                      <td>{tenderTable.tenderBody.row13.season}</td>
                      <td>{tenderTable.tenderBody.row13.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row13.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row13.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row13.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row14.srNo}</td>
                      <td>{tenderTable.tenderBody.row14.season}</td>
                      <td>{tenderTable.tenderBody.row14.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row14.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row14.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row14.export}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable.tenderBody.row15.srNo}</td>
                      <td>{tenderTable.tenderBody.row15.season}</td>
                      <td>{tenderTable.tenderBody.row15.seasonDays}</td>
                      <td>{tenderTable.tenderBody.row15.totalGeneration}</td>
                      <td>{tenderTable.tenderBody.row15.inHouseConsumption}</td>
                      <td>{tenderTable.tenderBody.row15.export}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CoGeneration;




