import React from "react";
import "./Labortime.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";

const Labortime = () => {
  const { t } = useTranslation();

  const { banner, laborContainer, tenderTable } = t("laborTime");

  var LaborImgs = {
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

  }

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="labor-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.heading}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-4 d-flex justify-content-center">
              <div className="col-md-6">
                <h2 className="investor-title mb-4">
                  {laborContainer.heading}
                </h2>
                <p className="department-sbtxt ">
                  {laborContainer.para}
                </p>
                <br />
                <p className="department-sbtxt ">
                  {laborContainer.para1}
                </p>
              </div>
              <div className="col-md-6">
                <div className="agriculture-multimg">
                  <Slider {...LaborImgs}>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="labortime"
                        src={getAssetPath("assets/imgs/department/labortime/labour1.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="labortime"
                        src={getAssetPath("assets/imgs/department/labortime/labour2.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="labortime"
                        src={getAssetPath("assets/imgs/department/labortime/labour3.jpg")}
                      ></img>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="facility-bg-color">
        <div className="container">
            <div className="col-md-12">
              {/* facility grid view */}
              <div className="container">
                <div className="login-search">
                  <div className="farmer-name">
                    <div className="username investor-title">
                      <h2 className="font-bold" style={{fontWeight : '600'}}>{tenderTable.table.heading}</h2>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath("assets/imgs/other-facility/vima.jpeg")}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">
                          {tenderTable.table1.heading}
                        </h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table1.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath(
                            "assets/imgs/other-facility/suraksha.jpeg"
                          )}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">{tenderTable.table2.heading}</h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table2.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath(
                            "assets/imgs/other-facility/prashikshan.jpeg"
                          )}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">
                        {tenderTable.table3.heading}
                        </h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table3.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath(
                            "assets/imgs/other-facility/sevak-mandal.jpeg"
                          )}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">{tenderTable.table4.heading}</h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table4.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath("assets/imgs/other-facility/kamgar-gratuity.png")}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">
                        {tenderTable.table5.heading}
                        </h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table5.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath(
                            "assets/imgs/other-facility/gunvant.jpeg"
                          )}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">
                        {tenderTable.table6.heading}
                        </h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table6.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath("assets/imgs/other-facility/arogya.jpeg")}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">{tenderTable.table7.heading}</h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table7.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath("assets/imgs/other-facility/kamgar.jpeg")}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">{tenderTable.table8.heading}</h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table8.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath("assets/imgs/other-facility/camp.png")}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">
                        {tenderTable.table9.heading}
                        </h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table9.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath("assets/imgs/other-facility/prathamopchar.jpeg")}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">{tenderTable.table10.heading}</h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table10.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath("assets/imgs/other-facility/kamgar-surkasha.jpeg")}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">{tenderTable.table11.heading}</h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table11.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath("assets/imgs/other-facility/pagar-pf.png")}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">{tenderTable.table12.heading}</h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table12.para}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="deve-facility">
                      <div className="cane-facility-img">
                        <img
                          className="img-fluid"
                          alt="other-facility"
                          src={getAssetPath("assets/imgs/other-facility/kusti.jpeg")}
                        ></img>
                      </div>
                      <div className="facility-info ">
                        <h4 className="facility-name font-bold">{tenderTable.table13.heading}</h4>
                        <p className="facility-desc font-regular">
                        {tenderTable.table13.para}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button custom-accordoin-btn" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      <h4 class="facility-name font-bold">{tenderTable.table1.heading}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{tenderTable.table1.para}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                        <h4 class="facility-name font-bold">{tenderTable.table2.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table2.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table3.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table3.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingfour">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table4.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapsefour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table4.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingfive">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefive" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table5.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapsefive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table5.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingsix">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsesix" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table6.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table6.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingseven">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseseven" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table7.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseseven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table7.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingeght">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseseght" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table8.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseseght" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table8.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingenine">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsesnine" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table9.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapsesnine" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table9.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingeten">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsesten" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table10.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapsesten" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table10.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingeeleven">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseseleven" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table11.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseseleven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table11.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingetweleve">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsestweleve" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{tenderTable.table12.heading}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapsestweleve" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="bt-text font-regular ">
                          <p>{tenderTable.table12.para}</p>
                        </div>
                      </div>
                    </div>
                </div>
              </div> */}
              {/* <section>
                <div className="bt-title font-semibold">
                <p>{tenderTable.table1.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table1.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table2.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table2.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table3.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table3.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table4.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table4.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table5.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table5.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table6.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table6.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table7.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table7.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table8.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table8.para}.</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table9.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table9.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table10.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table10.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table11.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table11.para}</p>
              </div>

              <div className="bt-title font-semibold">
                <p>{tenderTable.table12.heading}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{tenderTable.table12.para}</p>
              </div>
              </section> */}
            </div>
        </div>
      </section>
    </>
  );
};

export default Labortime;
