export const imageNames = [
    // 'civil-img6.jpeg',
    // 'civil-img7.jpeg',
    // 'civil-img8.jpeg',
    // 'civil-img9.jpeg',
    // 'civil-img11.jpeg',
    { id: 1, title: 'image1', thumbnail:'assets/imgs/department/civil/civil-min-1.jpg' },
    { id: 2, title: 'image2', thumbnail:'assets/imgs/department/civil/civil-min-2.jpg' },
    { id: 3, title: 'image3', thumbnail:'assets/imgs/department/civil/civil-min-3.jpg' },
    { id: 4, title: 'image4', thumbnail:'assets/imgs/department/civil/civil-min-4.jpg' },
    { id: 5, title: 'image5', thumbnail:'assets/imgs/department/civil/civil-min-5.jpg' },
    { id: 6, title: 'image6', thumbnail:'assets/imgs/department/civil/civil-min-6.jpg' },
    { id: 7, title: 'image7', thumbnail:'assets/imgs/department/civil/civil-min-7.jpg' },
    { id: 8, title: 'image8', thumbnail:'assets/imgs/department/civil/civil-min-8.jpg' },
 ];