import React, { useState  } from "react";
import "./AboutUs.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { getAssetPath } from "../../services/helpers";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { getLocalStorageData } from "../../services/localStorageService";

const AboutUs = () => {
  var overviewslider = {
    autoplay: false,
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    gap: 10,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  const navigate = useNavigate()
  const handleButtonClick = () => {
    if (getLocalStorageData('token') && parseInt(getLocalStorageData("roleId"), 10) === 2) {
        navigate('/Tenders')
    }else{
        navigate('/OfflineTender')
    }
}
  const { t } = useTranslation();

  // counter animation
  const [counterOn, setCounterOn] = useState(false);

  const {
    aboutUsBanner,
    simpleIdea,
    threeSections,
    visionMission,
    fourCards,
    companyOverview1,
    seasonSelectn,
    CompanySlider,
    AboutSlider,
    companyOverview2,
    awardsList,
  } = t("aboutUs");

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="Aboutus-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">
              {aboutUsBanner.heading}
            </h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{aboutUsBanner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{aboutUsBanner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {aboutUsBanner.link3}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="simple-idea abt-pdtop cane-deve-bg">
        <div className="row mrg-0">
          <div className="container col-md-6">
            {/* <h2 className="idea-title">Towards new age of Sugarcane</h2> */}
            <h2 className="idea-title">{simpleIdea.heading}</h2>
          </div>
          <div className="container col-md-10">
            <p className="idea-para abt-para-mrg">{simpleIdea.para}</p>
            <p className="idea-para">{simpleIdea.para1}</p>
          </div>
        </div>
        <div className="3-section">
          <div className="row mrg-0">
            <div className="col-md-4 leadership-bg">
              <div className="container mb-3" style={{ zIndex: "9" }}>
                <h4 className="leadersec-title font-bold">
                  {threeSections.section1.heading}
                </h4>
                {/* <p className="leadersec-sbtitle font-regular">
                  {threeSections.section1.para}
                </p> */}
                <a href="OurTeam">
                <button className="btn btn-default sec-lm-btn ">
                  {threeSections.section1.btn}
                  <img
                    className="Arrow-white"
                    alt="arrow"
                    src={getAssetPath("assets/icons/Arrow-1.png")}
                  ></img>
                </button>
                </a>
              </div>
            </div>
            <div className="col-md-4 investors-bg">
              <div className="container mb-3" style={{ zIndex: "9" }}>
                <h4 className="leadersec-title font-bold">
                  {threeSections.section2.heading}
                </h4>
                {/* <p className="leadersec-sbtitle font-regular">
                  {threeSections.section2.para}
                </p> */}
                <button className="btn btn-default sec-lm-btn" onClick={() => handleButtonClick()} >
                  {threeSections.section2.btn}
                  <img
                    className="Arrow-white"
                    alt="arrow"
                    src={getAssetPath("assets/icons/Arrow-1.png")}
                  ></img>
                </button>
              </div>
            </div>
            <div className="col-md-4 krantigallery-bg">
              <div className="container mb-3" style={{ zIndex: "9" }}>
                <h4 className="leadersec-title font-bold">
                  {threeSections.section3.heading}
                </h4>
                {/* <p className="leadersec-sbtitle font-regular">
                  {threeSections.section3.para}
                </p> */}
                <a href="KrantiGallery">
                <button className="btn btn-default sec-lm-btn ">
                  {threeSections.section3.btn}
                  <img
                    className="Arrow-white"
                    alt="arrow"
                    src={getAssetPath("assets/icons/Arrow-1.png")}
                  ></img>
                </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* vision mission */}
      <section className="company-overview">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="vision-mission">
                <p className="sb-heading font-semibold">
                  {visionMission.para1}
                </p>
                <div className="bold-reg">
                  <p className="regular-txt font-regular">
                    <span className="bold-we font-bold">
                      {visionMission.para2} 
                      &nbsp;
                      {visionMission.para3} {visionMission.para4} {visionMission.para5}
                    </span>
                  {/* </p>
                  <p className="regular-txt font-regular"> */}
                  </p>
                </div>
                <p className="regular-txt font-regular">
                  
                </p>
                <p className="regular-txt font-regular">
                  
                </p>

                <p className="vm-brief font-regular">{visionMission.para6}</p>
              </div>
            </div>
            <div className="col-md-7">
              <div className="four-cards">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card-bg">
                      <img
                        className="four-cardimg"
                        alt="farmer"
                        src="assets/imgs/aboutus-img/social.png"
                      ></img>
                      <h2 className="four-card-title font-bold">
                        {fourCards.card1.heading}
                      </h2>
                      {/* <p className="four-card-sbtxt font-regular">
                        {fourCards.card1.para}
                      </p> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-bg">
                      <img
                        className="four-cardimg"
                        alt="sustain"
                        src="assets/imgs/aboutus-img/culture.png"
                      ></img>
                      <h2 className="four-card-title font-bold">
                        {fourCards.card2.heading}
                      </h2>
                      {/* <p className="four-card-sbtxt font-regular">
                        {fourCards.card2.para}
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="card-bg">
                      <img
                        className="four-cardimg"
                        alt="culture"
                        src="assets/imgs/aboutus-img/edu.png"
                      ></img>
                      <h2 className="four-card-title font-bold">
                        {fourCards.card3.heading}
                      </h2>
                      {/* <p className="four-card-sbtxt font-regular">
                        {fourCards.card3.para}
                      </p> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-bg">
                      <img
                        className="four-cardimg"
                        alt="experience"
                        src="assets/imgs/aboutus-img/money.png"
                      ></img>
                      <h2 className="four-card-title font-bold">
                        {fourCards.card4.heading}
                      </h2>
                      {/* <p className="four-card-sbtxt font-regular">
                        {fourCards.card4.para}
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* company-overview */}
      {/* <section className="company-overview bg-white">
        <div className="cmpn-over-wrp abt-cmpn-over-wrp">
          <div className="container cmpn-details">
            <div className="season-selectn-heading">
              <div className="overview-title">
                <div className="col-md-12">
                  <h2 className="co-title font-bold">
                    {companyOverview1.title}
                  </h2>
                </div>
                <div className="col-md-12">
                  <p className="pd-subtxt font-regular">
                    {companyOverview1.pdSubtext}
                  </p>
                </div>
              </div>
              <div className="season-selectn">
                <p className="select-title">{seasonSelectn.selectTitle}</p>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {seasonSelectn.button}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item">
                        {seasonSelectn.dropDownItems.item1}
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" >
                        {seasonSelectn.dropDownItems.item2}
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" >
                        {seasonSelectn.dropDownItems.item3}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="data-row">
              <Slider {...overviewslider}>
                <div className="col-md-2">
                  <div className="glass-bg gap-itm">
                    <p className="crushing font-medium">
                      {CompanySlider.crushingOne.para1}<br/>{CompanySlider.crushingOne.paraone}
                    </p>
                    <h4 className="font-bold crushing-value">
                      {CompanySlider.crushingOne.crushingValue}
                    </h4>
                    <p className="fy-year font-regular">
                      {CompanySlider.crushingOne.para2}
                    </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="glass-bg gap-itm">
                    <p className="crushing font-medium">
                      {CompanySlider.crushingTwo.para1}<br/>{CompanySlider.crushingTwo.paraone}
                    </p>
                    <h4 className="font-bold crushing-value">
                      {CompanySlider.crushingTwo.crushingValue}
                    </h4>
                    <p className="fy-year font-regular">
                      {CompanySlider.crushingTwo.para2}
                    </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="glass-bg gap-itm">
                    <p className="crushing font-medium">
                      {CompanySlider.crushingThree.para1}<br/>{CompanySlider.crushingThree.paraone}
                    </p>
                    <h4 className="font-bold crushing-value">
                      {CompanySlider.crushingThree.crushingValue}
                    </h4>
                    <p className="fy-year font-regular">
                      {CompanySlider.crushingThree.para2}
                    </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="glass-bg gap-itm">
                    <p className="crushing font-medium">
                      {CompanySlider.crushingFour.para1}<br/>{CompanySlider.crushingFour.paraone}
                    </p>
                    <h4 className="font-bold crushing-value">
                      {CompanySlider.crushingFour.crushingValue}
                    </h4>
                    <p className="fy-year font-regular">
                      {CompanySlider.crushingFour.para2}
                    </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="glass-bg gap-itm">
                    <p className="crushing font-medium">
                      {CompanySlider.crushingFive.para1}<br/>{CompanySlider.crushingFive.paraone}
                    </p>
                    <h4 className="font-bold crushing-value">
                      {CompanySlider.crushingFive.crushingValue}
                    </h4>
                    <p className="fy-year font-regular">
                      {CompanySlider.crushingFive.para2}
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section> */}

      {/* new company-overview */}
      <section className="new-compnayover-bg">
          <div className="container">
              <div className="row d-flex align-items-start">
                  <div className="col-md-4 ovr-pd-lft">
                      <p className="pd-newmintxt font-medium">{companyOverview1.pdSubtext}</p>
                      <h2 className="co-newtitle font-bold mb-4">{companyOverview1.title}</h2>
                      <p className="pd-newsubtxt font-regular">{companyOverview1.pdSubtxt}</p>
                  </div>
                  <div className="offset-md-1 col-md-7 ovr-pd-lft">
                  <div className="season-selectn">
                  <p className="select-title font-regular">{seasonSelectn.selectTitle}</p>
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {seasonSelectn.button}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a className="dropdown-item">
                              {seasonSelectn.dropDownItems.item1}
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" >
                              {seasonSelectn.dropDownItems.item2}
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" >
                              {seasonSelectn.dropDownItems.item3}
                            </a>
                          </li>
                        </ul>
                      </div>
                  </div>
                      <div className="row">
                          <div className="col-md-12">
                              <div className="row">
                                  <div className="col-md-4">
                                      <div className="company-overview-card">
                                          <div className="icon-data">
                                              <img className="coc-icon" alt="coc-doc" src={getAssetPath("assets/icons/capacity.png")}></img>
                                              <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
                                                  <h4 className="font-bold crushing-value-new">
                                                      {counterOn && <CountUp end={7500} duration={2} delay={0}/>}
                                                  </h4>
                                              </ScrollTrigger>
                                          </div>
                                          <div className="coc-title">
                                              <p className="crushing-sbtxt font-medium">{AboutSlider.crushingOne.para1}<br/>{AboutSlider.crushingOne.paraone}</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="company-overview-card">
                                          <div className="icon-data">
                                              <img className="coc-icon" alt="coc-doc" src={getAssetPath("assets/icons/cane-crushed.png")}></img>
                                              <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
                                                  <h4 className="font-bold crushing-value-new">
                                                      {counterOn && <CountUp end={1085410} duration={3} delay={0}/>}
                                                  </h4>
                                              </ScrollTrigger>
                                          </div>
                                          <div className="coc-title">
                                              <p className="crushing-sbtxt font-medium">{AboutSlider.crushingTwo.para1}<br/>{AboutSlider.crushingTwo.paraone}</p>
                                          </div>
                                      </div>
                                  </div>     
                                  <div className="col-md-4">
                                      <div className="company-overview-card last">
                                          <div className="icon-data">
                                              <img className="coc-icon" alt="coc-doc" src={getAssetPath("assets/icons/sugar-production.png")}></img>
                                              <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
                                                  <h4 className="font-bold crushing-value-new">
                                                      {counterOn && <CountUp end={1175560} duration={3} delay={0}/>}
                                                  </h4>
                                              </ScrollTrigger>
                                          </div>
                                          <div className="coc-title">
                                              <p className="crushing-sbtxt font-medium">{AboutSlider.crushingThree.para1}<br/>{AboutSlider.crushingThree.paraone}</p>
                                          </div>
                                      </div>
                                  </div>    
                              </div>
                              <div className="row mrg-common">
                                  <div className="col-md-4">
                                      <div className="company-overview-card">
                                          <div className="icon-data">
                                              <img className="coc-icon" alt="coc-doc" src={getAssetPath("assets/icons/sugar-recovery.png")}></img>
                                              <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
                                                  <h4 className="font-bold crushing-value-new">
                                                      {counterOn && <CountUp end={AboutSlider.crushingFour.crushingValue} duration={2} delay={0} decimals={2} />}
                                                  </h4>
                                              </ScrollTrigger>
                                          </div>
                                          <div className="coc-title">
                                              <p className="crushing-sbtxt font-medium">{AboutSlider.crushingFour.para1}<br/>{AboutSlider.crushingFour.paraone}</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="company-overview-card last">
                                          <div className="icon-data">
                                              <img className="coc-icon" alt="coc-doc" src={getAssetPath("assets/icons/sugarcane-rate.png")}></img>
                                              <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
                                                  <h4 className="font-bold crushing-value-new">
                                                      {counterOn && <CountUp end={3100} duration={2} useIndianSeparators={true} delay={0}/>}
                                                  </h4>
                                              </ScrollTrigger>
                                          </div>
                                          <div className="coc-title">
                                              <p className="crushing-sbtxt font-medium">{AboutSlider.crushingFive.para1}<br/>{AboutSlider.crushingFive.paraone}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      {/* awards-achievements */}
      <section className="company-overview">
        <div className="container">
          <div className="col-md-6">
            <h2 className="investor-title">{companyOverview2.investorTitle}</h2>
            <p className="facility-sbtxt font-medium">
              {companyOverview2.facilitySbtxt}
            </p>
          </div>

          {/* desktop-view */}
          <div className="row mt-5 awards-desktop">
            <div className="col-md-3">
              <div className="awards-list">
                <div className="awards-img">
                  <img
                    className="img-fluid"
                    alt="awards"
                    src="assets/imgs/awards/trophy1.png"
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="awards-list">
                <div className="awards-img">
                  <img
                    className="img-fluid"
                    alt="awards"
                    src="assets/imgs/awards/certificate.png"
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="awards-list">
                <div className="awards-img">
                  <img
                    className="img-fluid"
                    alt="awards"
                    src="assets/imgs/awards/trophy2.png"
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="awards-list">
                <div className="awards-img">
                  <img
                    className="img-fluid"
                    alt="awards"
                    src="assets/imgs/awards/certificate2.png"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          {/* mobile-view */}
          <div className="row awards-mobile">
            <div className="col-md-12">
              <div
                className="facility-card"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="facility-main-card">
                  <div className="facility-img">
                    <img
                      className="mobile-facilityimg"
                      alt="awards"
                      src="assets/imgs/awards/trophy1.png"
                    ></img>
                  </div>
                </div>
              </div>
              <div
                className="facility-card"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="facility-main-card">
                  <div className="facility-img">
                    <img
                      className="mobile-facilityimg"
                      alt="awards"
                      src="assets/imgs/awards/certificate.png"
                    ></img>
                  </div>
                </div>
              </div>
              <div
                className="facility-card"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="facility-main-card">
                  <div className="facility-img">
                    <img
                      className="mobile-facilityimg"
                      alt="awards"
                      src="assets/imgs/awards/trophy2.png"
                    ></img>
                  </div>
                </div>
              </div>
              <div
                className="facility-card"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="facility-main-card">
                  <div className="facility-img">
                    <img
                      className="mobile-facilityimg"
                      alt="awards"
                      src="assets/imgs/awards/certificate2.png"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
