import React, { useEffect } from "react";
import "./TenderLogin.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginQueryMutation } from "../../services/userAuthApi";
import {
  storeData,
  getLocalStorageData,
} from "../../services/localStorageService";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const TenderLogin = () => {
  const { t } = useTranslation();
  const { form ,requiredMsg } = t("login");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    const token = getLocalStorageData("token");
    if (token) {
      const roleId = getLocalStorageData("roleId");
      if (roleId === "1") {
        navigate("/FarmerDetails");
      } else if (roleId === "2") {
        navigate("/Tenders");
      }
    }
  });

  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });
  const [loginUser] = useLoginQueryMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get("email"),
      password: data.get("password"),
      role_id: data.get("role_id"),
      agree_terms: data.get("agree_terms") === "true" ? 1 : 0,
      lang: i18next.language
    };
    if (actualData.email && actualData.password && actualData.agree_terms) {
      const res = await loginUser(actualData);
      if (res.data && res.data.message === "success") {
        storeData("token", res.data.data.token);
        storeData("roleId", res.data.data.user_details.role_id);
        storeData(
          "userName",
          res.data.data.user_details.name.charAt(0).toUpperCase() +
            res.data.data.user_details.name.slice(1)
        );

        if (res.data.data.user_details.role_id === 2) {
          navigate("/Tenders");
        } else {
          navigate("/FarmerDetails");
        }
      }
      if (res.error) {
        setError({ status: true, msg: res.error.data.message, type: "error" });
      }
    } else {
      setError({ status: true, msg: requiredMsg, type: "error" });
    }
  };


  return (
    <>
      <section className="login-bg">
        <div className="slider-login fslogin-bg">
          <div className="login-content">
            <div className="container">
              <div className="container login-form-row col-md-6 mg-tp">
                <p className="col-12 txt-center font-bold">{form.supplierForm.heading}</p>
                <div className="tab-content custom-tb-cnt" id="myTabContent">
                  {error.status ? (
                    <div className="alert alert-danger">{error.msg}</div>
                  ) : (
                    ""
                  )}
                  <div
                    className="tab-pane fade show active"
                    id="Supplier"
                    role="tabpanel"
                    aria-labelledby="Supplier-tab"
                  >
                    <form id="supplier-form" onSubmit={handleSubmit}>
                      <input type="hidden" name="role_id" value="2" />
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          alt="icon"
                          src="assets/icons/user-icon.png"
                        ></img>
                        <input
                          placeholder={form.supplierForm.username}
                          id="email"
                          name="email"
                          className="form-control rounded-0 font-regular"
                          type="text"
                        />
                      </div>
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          alt="icon"
                          src="assets/icons/password-icon.png"
                        ></img>
                        <input
                          placeholder={form.supplierForm.password}
                          id="password"
                          name="password"
                          className="form-control rounded-0 font-regular"
                          type={showPassword ? "text" : "password"}
                        />
                        <button
                          type="button"
                          className="btn btn-round show-hide-btn"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <img
                              className="show-pass"
                              alt="icon"
                              src="assets/icons/show-pass.png"
                            />
                          ) : (
                            <img
                              className="hide-pass"
                              alt="icon"
                              src="assets/icons/hide-pass.png"
                            />
                          )}
                        </button>
                      </div>
                      <label className="mb-3">
                        <a href="resetLink">{form.supplierForm.forgotPass}</a>
                      </label>
                      <div className="form-check mb-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={true}
                          id="agree_terms"
                          name="agree_terms"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          {form.supplierForm.T_C1} <a href="">{form.supplierForm.T_C2}.</a>
                        </label>
                      </div>
                      <button className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold">
                        {form.supplierForm.loginBtn}
                        <img
                          className="Arrow-1"
                          alt="icon"
                          src="assets/icons/Arrow-1.png"
                        ></img>
                      </button>
                    </form>
                    <p className="register-link">
                      {form.supplierForm.text1} <a href="TenderRegister">{form.supplierForm.text2}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TenderLogin;
