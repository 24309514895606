import React, { useEffect, useState } from "react";
import "./ESL.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";
import { useGetBrochureListQuery } from "../../../services/userAuthApi";


const ESL = () => {
  const [pdfBrochure, setPdfBrochure] = useState([]);
  const { data: brochureList, isSuccess, isLoading } = useGetBrochureListQuery();
  const { t } =  useTranslation();
  const { banner, newsRoom, brochures } = t("ESL");
    useEffect(() => {
      if (brochureList && isSuccess) {
          const response = brochureList.data;
          setPdfBrochure(response);
      }
  }, [brochureList, isSuccess])

  if (isLoading) return <div>Loading...</div>
  const getFileName = (url) => {
    var path = url.pdf_file;
    const fileNameWithExt = path.substring(path.lastIndexOf('/') + 1);
    const fileName = fileNameWithExt.substring(0, fileNameWithExt.lastIndexOf('.'));
    return fileName;
  };

  var EslImgs = {
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

  }
  return (
    <>
      <div className="Tenders-headbanner">
        <div className="esl-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">
              {banner.title}
            </h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-4 d-flex justify-content-center">
              <div className="col-md-6">
                <h2 className="investor-title mb-4">
                  {newsRoom.heading}
                </h2>
                <p className="department-sbtxt ">
                  {newsRoom.para1}
                </p>
                <br/>
                <p className="department-sbtxt ">
                  {newsRoom.para2}
                </p>
              </div>
              <div className="col-md-6">
                <div className="agriculture-multimg">
                  <Slider {...EslImgs}>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/esl/esl-img1.jpeg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/esl/esl-img2.jpeg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/esl/esl-img3.jpeg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/esl/esl-img4.jpeg")}
                      ></img>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       {/* broucher list */}
       <section className="ngfc-bg" style={{backgroundColor : "#f6f6f6", paddingBottom : "100px"}}>
            <div className="container">
                <div className="newsroom">
                    <div className="col-md-7">
                        <h2 className="investor-title mb-2" style={{fontSize : "32px"}}>{brochures.title}</h2>
                        <p className="department-sbtxt ">
                          {brochures.subText}
                        </p>
                    </div>
                    <div className="col-md-12">
                        <div className="broucher-list">
                        {pdfBrochure.length > 0 ? (
                          pdfBrochure.map((file,index)=>(
                            <li key={index} className="broucher">
                              <a href={file.pdf_file} target="_blank">
                                {getFileName(file)}
                              </a>
                              <img
                                    className="broucher-icon"
                                    alt="tender-doc"
                                    src={getAssetPath("assets/icons/ion_document.png")}
                                  />
                            </li>
                          ))
                        )
                        : ""}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};

export default ESL;

