import React, { useState } from "react";
import "./ResetLink.css";
import { useSendResetLinkEmailMutation } from "../../../services/userAuthApi";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ResetLink = () => {

  const { t } = useTranslation();

  const {heading, emailPlaceholder, backToLogin, btnText1, btnText2 } = t("resetLink");

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendEmailLink] = useSendResetLinkEmailMutation(); // send email link
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const handleButtonClick = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);
    try {
      let lang = i18next.language;
      const response = await sendEmailLink({email,lang});
      if (response.error) {
        setError(response.error.data.message);
      } else {
        setSuccess(response.data.message);
      }
    } catch (err) {
      setError(err.data?.message || "Failed to send reset link.");
    } finally {
      setLoading(false); // Set loading back to false after response is received
    }
  };
  return (
    <>
      <section className="login-bg">
        <div className="slider-login fslogin-bg">
          <div className="login-content">
            <div className="container">
              <div className="container login-form-row col-md-6 mg-tp">
                <p className="col-12 txt-center font-bold">{heading}</p>
                {error && <div className="alert alert-danger">{error}</div>}
                {success && (
                  <div className="alert alert-success">{success}</div>
                )}
                <div className="tab-content custom-tb-cnt" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="Supplier"
                    role="tabpanel"
                    aria-labelledby="Supplier-tab"
                  >
                    <div className="input-grp">
                      <img
                        className="user-icon"
                        alt="icon"
                        src={getAssetPath("assets/icons/mail-icon.png")}
                      ></img>
                      <input
                        placeholder={emailPlaceholder}
                        id="email"
                        name="email"
                        className="form-control rounded-0 font-regular"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <label className="mb-3">
                      <a href="TenderLogin">{backToLogin}</a>
                    </label>
                    <a href="/NewPassword">
                      <button
                        className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold"
                        onClick={handleButtonClick}
                        disabled={loading}
                      >
                        {loading ? `${btnText1}` : `${btnText2}`}
                        <img
                          className="Arrow-1"
                          alt="icon"
                          src={getAssetPath("assets/icons/Arrow-1.png")}
                        ></img>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetLink;
