import React from "react";
import "./Computer.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";

const Computer = () => {
  const { t } = useTranslation();

  const { banner, newsRoom, tenderTable1, tenderTable2 } = t("computer");

  var ComputerImgs = {
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

  }

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="computer-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-4 d-flex justify-content-center">
              <div className="col-md-6">
                <h2 className="investor-title mb-5">{newsRoom.heading}</h2>
                <p className="department-sbtxt ">{newsRoom.para}</p>
                <br />
                <p className="department-sbtxt ">{newsRoom.para1}</p>
                <br />
                <p className="department-sbtxt ">{newsRoom.para2}</p>
                <br />
              </div>
              <div className="col-md-6">
                <div className="agriculture-multimg">
                <Slider {...ComputerImgs}>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/computer/comp1.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/computer/comp2.jpg")}
                      ></img>
                    </div>
                    {/* <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/computer/comp3.jpg")}
                      ></img>
                    </div> */}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="row">
                {/* <div className="col-md-12">
                  <h4 class="facility-name font-bold">{newsRoom.heading1}</h4>
                  <br />
                  <h4 class="facility-name font-bold">{newsRoom.subheading1}</h4>
                  <p className="department-sbtxt ">{newsRoom.para3}</p>
                  <br/>
                  <h4 class="facility-name font-bold">{newsRoom.subheading2}</h4>
                  <p className="department-sbtxt ">{newsRoom.para4}</p>
                  <br/>
                  <h4 class="facility-name font-bold">{newsRoom.subheading3}</h4>
                  <p className="department-sbtxt ">{newsRoom.para5}</p>
                  <br/>
                  <h4 class="facility-name font-bold">{newsRoom.subheading4}</h4>
                  <p className="department-sbtxt ">{newsRoom.para6}</p>
                  <br/>
                </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="tender-table">
        <div className="container">
          <div className="tendermain-table">
            <div className="col-md-12">
              <div className="login-search">
                <div className="farmer-name">
                  <div className="username investor-title">
                    <h2 className="font-bold" style={{fontWeight : '600'}}>{newsRoom.heading1}</h2>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button class="accordion-button custom-accordoin-btn" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                        <h4 class="facility-name font-bold">{newsRoom.subheading1}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                      <div class="accordion-body">
                        <div className="row">
                          <div className="col-md-4">
                            <img className="collapse-imgs cmputer-imgs" alt="computer" src={getAssetPath("assets/imgs/new-home-facility1.png")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text">
                              <p className="department-sbtxt ">{newsRoom.para3}</p>
                              <br/>
                              <p className="department-sbtxt ">{newsRoom.paratwo}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                        <h4 class="facility-name font-bold">{newsRoom.subheading2}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                      <div class="accordion-body">
                        <div className="row">
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="computer" src={getAssetPath("assets/imgs/department/computer/computer-facility-img1.jpg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text">
                              <p className="department-sbtxt ">{newsRoom.para4}</p>
                              <br />
                              <p className="department-sbtxt ">{newsRoom.parathree}</p>
                              <br />
                              <p className="department-sbtxt ">{newsRoom.parathree1}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{newsRoom.subheading3}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="row">
                          <div className="col-md-4">
                            <img className="collapse-imgs  cmputer-imgs" alt="computer" src={getAssetPath("assets/imgs/new-home-facility3.png")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text">
                              <p className="department-sbtxt ">{newsRoom.para5}</p>
                              <br />
                              <p className="department-sbtxt ">{newsRoom.parafour}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item custom-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                      <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        <h4 class="facility-name font-bold">{newsRoom.subheading4}</h4>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div className="row">
                          <div className="col-md-4">
                            <img className="collapse-imgs cmputer-imgs" alt="computer" src={getAssetPath("assets/imgs/new-home-facility2.png")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text">
                              <p className="department-sbtxt ">{newsRoom.para6}</p>
                              <br />
                              <p className="department-sbtxt ">{newsRoom.parafive}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              {/* <div className="table-responsive mb-3">
                <table className="table table-striped table-hover ">
                  <thead className="thead-dark th-height">
                    <tr>
                      <th scope="col">{tenderTable1.tableHead.text1}</th>
                      <th scope="col">{tenderTable1.tableHead.text2}</th>
                      <th scope="col">{tenderTable1.tableHead.text3}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tr-height">
                      <td className="font-semibold">
                        {tenderTable1.tableBody.section1.row1.text1}
                      </td>
                      <td className="font-semibold" colSpan={2}>
                        {tenderTable1.tableBody.section1.row1.text2}
                      </td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable1.tableBody.section1.row2.text1}</td>
                      <td>{tenderTable1.tableBody.section1.row2.text2}</td>
                      <td>{tenderTable1.tableBody.section1.row2.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td className="font-semibold">
                        {tenderTable1.tableBody.section2.row1.text1}
                      </td>
                      <td className="font-semibold" colSpan={2}>
                        {tenderTable1.tableBody.section2.row1.text2}
                      </td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable1.tableBody.section2.row2.text1}</td>
                      <td>{tenderTable1.tableBody.section2.row2.text2}</td>
                      <td>{tenderTable1.tableBody.section2.row2.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable1.tableBody.section2.row3.text1}</td>
                      <td>{tenderTable1.tableBody.section2.row3.text2}</td>
                      <td>{tenderTable1.tableBody.section2.row3.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable1.tableBody.section2.row4.text1}</td>
                      <td>{tenderTable1.tableBody.section2.row4.text2}</td>
                      <td>{tenderTable1.tableBody.section2.row4.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable1.tableBody.section2.row5.text1}</td>
                      <td>{tenderTable1.tableBody.section2.row5.text2}</td>
                      <td>{tenderTable1.tableBody.section2.row5.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable1.tableBody.section2.row6.text1}</td>
                      <td>{tenderTable1.tableBody.section2.row6.text2}</td>
                      <td>{tenderTable1.tableBody.section2.row6.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable1.tableBody.section2.row7.text1}</td>
                      <td>{tenderTable1.tableBody.section2.row7.text2}</td>
                      <td>{tenderTable1.tableBody.section2.row7.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable1.tableBody.section2.row8.text1}</td>
                      <td>{tenderTable1.tableBody.section2.row8.text2}</td>
                      <td>{tenderTable1.tableBody.section2.row8.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable1.tableBody.section2.row9.text1}</td>
                      <td>{tenderTable1.tableBody.section2.row9.text2}</td>
                      <td>{tenderTable1.tableBody.section2.row9.text3}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="login-search">
                <div className="farmer-name">
                  <div className="username">
                    <h2 className="font-bold">{tenderTable2.heading}</h2>
                  </div>
                </div>
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-striped table-hover ">
                  <thead className="thead-dark th-height">
                    <tr>
                      <th scope="col">{tenderTable2.textHead.text1}</th>
                      <th scope="col">{tenderTable2.textHead.text2}</th>
                      <th scope="col">{tenderTable2.textHead.text3}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tr-height">
                      <td className="font-semibold">
                        {tenderTable2.textBody.section1.row.text1}
                      </td>
                      <td className="font-semibold" colSpan={2}>
                        {tenderTable2.textBody.section1.row.text2}
                      </td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section1.row1.text1}</td>
                      <td>{tenderTable2.textBody.section1.row1.text2}</td>
                      <td>{tenderTable2.textBody.section1.row1.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section1.row2.text1}</td>
                      <td>{tenderTable2.textBody.section1.row2.text2}</td>
                      <td>{tenderTable2.textBody.section1.row2.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td colSpan={3}></td>
                    </tr>

                    <tr className="tr-height">
                      <td className="font-semibold">
                        {tenderTable2.textBody.section2.row.text1}
                      </td>
                      <td className="font-semibold" colSpan={2}>
                        {tenderTable2.textBody.section2.row.text2}
                      </td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section2.row1.text1}</td>
                      <td>{tenderTable2.textBody.section2.row1.text2}</td>
                      <td>{tenderTable2.textBody.section2.row1.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section2.row2.text1}</td>
                      <td>{tenderTable2.textBody.section2.row2.text2}</td>
                      <td>{tenderTable2.textBody.section2.row2.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td colSpan={3}></td>
                    </tr>

                    <tr className="tr-height">
                      <td className="font-semibold">
                        {tenderTable2.textBody.section3.row.text1}
                      </td>
                      <td className="font-semibold" colSpan={2}>
                        {tenderTable2.textBody.section3.row.text2}
                      </td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section3.row1.text1}</td>
                      <td>{tenderTable2.textBody.section3.row1.text2}</td>
                      <td> {tenderTable2.textBody.section3.row1.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td colSpan={3}></td>
                    </tr>

                    <tr className="tr-height">
                      <td className="font-semibold">
                        {tenderTable2.textBody.section4.row.text1}
                      </td>
                      <td className="font-semibold" colSpan={2}>
                        {tenderTable2.textBody.section4.row.text2}
                      </td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section4.row1.text1}</td>
                      <td>{tenderTable2.textBody.section4.row1.text2}</td>
                      <td>{tenderTable2.textBody.section4.row1.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td colSpan={3}></td>
                    </tr>

                    <tr className="tr-height">
                      <td className="font-semibold">
                        {tenderTable2.textBody.section5.row.text1}
                      </td>
                      <td className="font-semibold" colSpan={2}>
                        {tenderTable2.textBody.section5.row.text2}
                      </td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section5.row1.text1}</td>
                      <td>{tenderTable2.textBody.section5.row1.text2}</td>
                      <td>{tenderTable2.textBody.section5.row1.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section5.row2.text1}</td>
                      <td>{tenderTable2.textBody.section5.row2.text2}</td>
                      <td>{tenderTable2.textBody.section5.row2.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section5.row3.text1}</td>
                      <td>{tenderTable2.textBody.section5.row3.text2}</td>
                      <td>{tenderTable2.textBody.section5.row3.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section5.row4.text1}</td>
                      <td>{tenderTable2.textBody.section5.row4.text2}</td>
                      <td>{tenderTable2.textBody.section5.row4.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section5.row5.text1}</td>
                      <td>{tenderTable2.textBody.section5.row5.text2}</td>
                      <td>{tenderTable2.textBody.section5.row5.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td colSpan={3}></td>
                    </tr>

                    <tr className="tr-height">
                      <td className="font-semibold">{tenderTable2.textBody.section6.row.text1}</td>
                      <td className="font-semibold" colSpan={2}>
                      {tenderTable2.textBody.section6.row.text2}
                      </td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section6.row1.text1}</td>
                      <td>{tenderTable2.textBody.section6.row1.text2}</td>
                      <td>{tenderTable2.textBody.section6.row1.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section6.row2.text1}</td>
                      <td>{tenderTable2.textBody.section6.row2.text2}</td>
                      <td>{tenderTable2.textBody.section6.row2.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section6.row3.text1}</td>
                      <td>{tenderTable2.textBody.section6.row3.text2}</td>
                      <td>{tenderTable2.textBody.section6.row3.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section6.row4.text1}</td>
                      <td>{tenderTable2.textBody.section6.row4.text2}</td>
                      <td>{tenderTable2.textBody.section6.row4.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section6.row5.text1}</td>
                      <td>{tenderTable2.textBody.section6.row5.text2}</td>
                      <td>{tenderTable2.textBody.section6.row5.text3}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderTable2.textBody.section6.row6.text1}</td>
                      <td>{tenderTable2.textBody.section6.row6.text2}</td>
                      <td>{tenderTable2.textBody.section6.row6.text3}</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Computer;