import React, { useState } from "react";
import './KrantiGallery.css'
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";
import { imageNames , galleryVideos } from "./staticImageArray";

const KrantiGallery = () => {
    const { t } = useTranslation();
    const { heading, link1, link2, btn1, btn2 } = t("KrantiGallery");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const openModal = (imageName) => {
        setSelectedImage(imageName);
    }

    const openVideoModal = (videoName) => {
        setSelectedVideo(videoName);
    }
    return(
        <>
        <div className="Tenders-headbanner">
            <div className="gallery-banner col-md-12">
                <div className="container">
                    <h1 className="headingtenders font-bold">{heading}</h1>
                    <div className="breadcrum">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-container">
                            <li className="breadcrumb-item brdm-itm"><a href="/home">{link1}</a></li>
                            <li className="breadcrumb-item brdm-itm active" aria-current="page">{link2}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <section className="gallery-tabs">
            <div className="container">
                <ul className="nav nav-pills justify-content-center" id="pills-tab" role="tablist">
                    <div className="awards-bg-pill">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active custom-tab" id="Pg-tab" data-bs-toggle="tab" data-bs-target="#Pg" type="button" role="tab" aria-controls="Pg" aria-selected="true">{btn1}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link custom-tab" id="Vg-tab" data-bs-toggle="tab" data-bs-target="#Vg" type="button" role="tab" aria-controls="Vg" aria-selected="false">{btn2}</button>
                        </li>
                    </div>
                </ul>
            </div>
            <div className="tab-content custom-tb-cnt" id="myTabContent">
                <div className="tab-pane fade show active" id="Pg" role="tabpanel" aria-labelledby="Pg-tab">
                    <section className="tab-awards-bg">
                        <div className="container">
                            <div className="row">
                                {imageNames.map((imageName, index) => (
                                    <div className="col-md-4">
                                        <div className="deve-facility">
                                            <div className="cane-facility-img" data-bs-toggle="modal" data-bs-target="#Modalimg" key={index}  onClick={() => openModal(imageName)}>
                                                <img className="img-fluid gallery-img" src={getAssetPath(`assets/imgs/compressed-kranti-gallery/${imageName}`)} alt={`Gallery ${index + 1}`} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
                <div className="tab-pane fade show" id="Vg" role="tabpanel" aria-labelledby="Vg-tab">
                    <section className="tab-awards-bg">
                        <div className="container">
                            <div className="row">
                            {galleryVideos.map((video, index) => (
                                <div className="col-md-4">
                                        <div className="deve-facility">
                                            <div className="cane-facility-img" data-bs-toggle="modal" data-bs-target="#Modalvideo" key={index}  onClick={() => openVideoModal(video.url)}>
                                                <img className="img-fluid gallery-img" src={video.thumbnail} alt={video.title} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                    </section>
                </div>
            </div>
        </section>
        <div className="modal fade" id="Modalimg" tabIndex="-1" aria-labelledby="Modalimg" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <a href="/" className="close" data-bs-dismiss="modal">
                            <img className="img-fluid" alt="close-modal" src={getAssetPath("assets/icons/close-modal.png")}></img>
                        </a>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadership-modal-img">
                                    <img className="img-fluid ks-gallery-img w-100" alt="gallery-img" src={getAssetPath(`assets/imgs/compressed-kranti-gallery/${selectedImage}`)}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="Modalvideo" tabIndex="-1" aria-labelledby="Modalvideo" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <a href="/" className="close" data-bs-dismiss="modal">
                            <img className="img-fluid" alt="close-modal" src={getAssetPath("assets/icons/close-modal.png")}></img>
                        </a>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadership-modal-img">
                                    <video width="100%" height="500" controls autoPlay controlsList="nodownload" src={selectedVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" className="" allowfullscreen>
                                    </video>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default KrantiGallery