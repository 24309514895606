const storeData = (name ,value) => {
  localStorage.setItem(name, value);
};
const getLocalStorageData = (name) => {
  let item = localStorage.getItem(name);
  return item;
};
const removeLocalStorageData = () => {
  // localStorage.removeItem(value);
   localStorage.clear();
};

export { storeData, getLocalStorageData, removeLocalStorageData};
